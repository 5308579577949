(function ($) {
    var app = {
        loadPercentage      : 0,
        contentLoaded       : false,
        debug               : false,
        isThisMobile        : null,
        
        getCssVar: function(variable) {
            return getComputedStyle(document.body).getPropertyValue(variable).replace('px','').replace(' ','');
        },
        windowLoaded: function () {
            var _main = app;
            _main.contentLoaded = true;
            if (_main.loadPercentage >= 90) {
                _main.preloader.kill();
            }
        },
        windowResized: function () {            
            AOS.refresh();            
        },
        windowScrolled: function () {            
            pxtop = $(window).scrollTop();

            if (pxtop >= 1) {
                $('header').addClass('scrolled');
            } else {
                $('header').removeClass('scrolled');
            }
        },
        log: function (message) {
            var _main = this;
            _main.debug ? console.log(message) : '';
        },
        preloader: {
            run: function () {
                var _main = app;
                var percent = 20;
                _main.intervalJump(_main.helpers.getRandom(5, 35), percent, _main.helpers.getRandom(10, 20));
            },
            finish: function () {
                var _main = app;
                _main.intervalJump(_main.helpers.getRandom(5, 25), 90, 100);
            },
            kill: function () {
                var _main = app;
                if (_main.contentLoaded) {
                    $('#preloader').fadeOut(400, function () {
                        $(this).remove();
                    });
                }
            },
        },
        intervalJump: function (time, start, stop) {
            var percent = start;
            var jump = setInterval(function () {
                percent                 = percent + 2;
                let current             = $('.progress-bar').attr('data-percent');
                _main.loadPercentage    = current;
                
                $('.progress-bar')
                    .attr('data-percent', percent)
                    .css('height', percent + '%');
                if (percent === stop) {
                    clearInterval(jump);
                    tempInterval = setTimeout(function () {
                        _main.intervalJump(_main.helpers.getRandom(15, 45), percent, _main.helpers.getRandom(40, 80));
                    }, _main.helpers.getRandom(200, 400));
                }
                if (current >= 88 && current <= 90) {
                    clearInterval(jump);
                    _main.preloader.finish();
                } else if (current >= 96) {
                    clearInterval(jump);
                    _main.preloader.kill();
                }
            }, time);
        },
        helpers: {
            killInterval: function (whichOne) {
                clearInterval(whichOne);
            },
            getRandom: function (min, max) {
                return Math.floor(Math.random() * (max - min + 1) + min);
            },
        },
        isMobile: function () {
            var _main = this;
            if (/Mobi|Android/i.test(navigator.userAgent)) {
                _main.isThisMobile = true;
                _main.log('this is mobile');
                // return true;
            } else {
                _main.isThisMobile = false;
                _main.log('this is not mobile');
                // return false;
            }            
            // console.log('browser', navigator.userAgent);            
        },
        animations: function () {            
            AOS.init();
            // if (app.isMobile() === false) {
            if ( $(window).width() > app.breakpoint_xl ) {
                if ($('#main-container').length) {
                    var myFullpage = new fullpage('#main-container', {            
                        menu: 'header',                        
                        resize: true,
                        scrollOverflow: true,
                        css: false,
                        licenseKey: 'K9EBH-8R9CI-H3KEJ-J930H-ZMNTO',
                        autoScrolling:true,
                        responsive: 900,
                        fitSection: false,  
                        afterLoad: function( section, origin, destination, direction, trigger) {
                            // app.log('current section index: ',origin.index);
                            if(origin.index > 0){
                                $('header').addClass('scrolled');
                                // relaunch video
                                setTimeout(() => {
                                    $('.hero .slick-slider .slick-slide').find('video')[0].play();
                                }, 100);
                            } else {
                                $('header').removeClass('scrolled');
                            }
                        }
                    });                
                }            
            }                       
        },
        showLoader: function (target) {
            $(target).html('<div class="loader"></div>');
        },
        selectInputs: function () {
            if ($('.s2').length) {
                $('.s2').select2({
                    minimumResultsForSearch: Infinity
                });
            }
        },
        loadPage: function (get_from, target) {
            page_id = $(get_from).attr('data-page');
            page_url = base_url + '?p=' + page_id;
            $(target).load(page_url + ' .sections', function (data) {
                $(target).addClass('show');
                AOS.refreshHard();
                var scripts = $(data).find('script');
                app.selectInputs();
                if (scripts.length) {
                    $(scripts).each(function () {
                        if ($(this).attr('src')) {
                            $.getScript($(this).attr('src'));
                        } else {
                            eval($(this).html());
                        }
                    });
                }
            });
        },
        events: function () {
            $('body').on('click', 'a[href="#"]', function (e) {
                e.preventDefault();
            });            

            if ($(window).width() >= app.breakpoint_lg) {
                $('body').on('click', 'a[href^="tel:"]', function (e) {
                    e.preventDefault();
                });
            }

            // if (app.isMobile() === false) {
            if ( $(window).width() > app.breakpoint_xl ) {
                if ($('#main-container').length) {
                    $('section.section').each(function(){
                        $(this).append('<div class="scroll-down"></div>');
                    });
                    
                    // $('body').append('<div class="scroll-down"></div>');
                    
                    $('.scroll-down').on('click', function() {
                        // $.scrollify.next();
                        fullpage_api.moveSectionDown();
                    });
                }
            }
            
            $('input[aria-required="true"]').each(function(){
                $(this).parent('.wpcf7-form-control-wrap').append('<i class="asterisk">*</i>');
            });            
            
            $('.wpcf7-acceptance').each(function(){
                // console.log('acceptance found');
                $(this).parent('.wpcf7-form-control-wrap').parent('.legal').find('.show-more').append('<i class="asterisk">*</i>');
            });            
            
            setTimeout(() => {
                $('.wpcf7-submit').prop("disabled", false);    
            }, 1000);
            
            $('.wpcf7').on('change', function() {
                $('.wpcf7-submit').prop("disabled", false);    
            });

            $('body').on('click', '.overlay', function () {
                if ($('body').hasClass('has-aside')) {                    
                    $('.aside-toggle.close-icon').click();
                } else  if ($('body').hasClass('mobile-nav')) {
                    $('.hamburger').click();
                }                
            });

            $('body').on('click', '.aside-toggle, a[href="#formularz"]', function () {
                $('.aside-bar').toggleClass('show');
                $('body').toggleClass('no-scroll').toggleClass('has-aside').toggleClass('has-overlay');                
            });

            $('.aside-bar .main').mCustomScrollbar();

            if ($('.select2-results__options').length) {
                $('.select2-results__options').mCustomScrollbar();
            }

            $('.hamburger').on('click', function () {
                $(this).toggleClass('active');
                $('body').toggleClass('mobile-nav').toggleClass('has-overlay');
            });

            // document.addEventListener( 'wpcf7mailsent', function( event ) {
            //     location = base_url+'/';
            // }, false );

            // show/hide checkbox long copy
            {
                $('.show-more').on('click', function (e) {
                    e.preventDefault();
                    var thisSelector = $(this),
                        formId = thisSelector.closest('div.wpcf7').attr('id'),
                        checkboxClass = thisSelector.attr('class').split(' ');                    
                    // thisSelector.hide();
                    $('#' + formId + ' .legal-note.' + checkboxClass[1]).toggleClass('show');
                });               
            }

            
            
        },

        init: function () {
            _main = this;
            _main.events();
            _main.selectInputs();
            _main.preloader.run();
            _main.animations();
            _main.isMobile();
        },

        breakpoint_sm       : getComputedStyle(document.body).getPropertyValue('--breakpoint-sm').replace('px','').replace(' ',''),
        breakpoint_md       : getComputedStyle(document.body).getPropertyValue('--breakpoint-md').replace('px','').replace(' ',''),
        breakpoint_lg       : getComputedStyle(document.body).getPropertyValue('--breakpoint-lg').replace('px','').replace(' ',''),
        breakpoint_xl       : getComputedStyle(document.body).getPropertyValue('--breakpoint-xl').replace('px','').replace(' ',''),
        breakpoint_xxl      : getComputedStyle(document.body).getPropertyValue('--breakpoint-xxl').replace('px','').replace(' ',''),

    };

    $(document).ready(function () {
        app.init();
    });

    $(window).on('load', function () {
        app.windowLoaded();
    });

    $(window).on('resize orientationchange', function () {
        app.windowResized();
    });

    $(document).scroll(function () {
        app.windowScrolled();
    });

    window.app = app;

})(jQuery);
